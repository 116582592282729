<template>
  <v-container min-width="100%" max-width="100%" class="pa-0" fluid>
    <v-layout>
      <v-img :src="require('../assets/images/Superior8.png')" min-width="50%"></v-img>
    </v-layout>

    <v-layout column>
      <v-flex>
        <v-card class="pa-1 ma-0" color="grey lighten-3" id="dashboard">
          <v-card class="pa-3 ma-0">
            <v-layout wrap>
              <!-- Intro section ======================================================== -->
              <v-flex xs12 sm12 md12 lg12 xl12>
                <v-card
                  flat
                  color="transparent"
                  class="ma-0 body-1 font-weight-medium text-justify"
                >
                  <v-card
                    flat
                    color="transparent"
                    class="mt-4 ma-3 body-1 font-weight-medium text-justify"
                  >
                    <v-layout justify-center class="ma-4 mb-4">
                      <v-card color="blue" width="90%">
                        <v-card height="97%" class="pa-3 elevation-0">
                          <p>
                            Superior Pool Resurfacing specializes in resurfacing, maintenance, and remodeling
                            of pools all throughout the
                            <span
                              class="font-weight-black blue--text"
                            >South Florida region, including Dade, Broward, Palm Beach and Monroe Counties.</span>
                          </p>
                        </v-card>
                      </v-card>
                    </v-layout>
                    <v-card flat class="pa-0">
                      <v-carousel lazy height="28em" cycle>
                        <v-carousel-item v-for="(item,i) in items" v-bind:src="item.src" :key="i"></v-carousel-item>
                      </v-carousel>
                    </v-card>

                    <v-card-title
                      class="mt-3 display-1 font-weight-black pa-2 justify-center text-center fix blue--text text-decoration-underline"
                    >What is Resurfacing?</v-card-title>
                  </v-card>

                  <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="90%">
                      <v-card height="97%" class="pa-3 elevation-0">
                        <p>Resurfacing is the process of draining the pool and applying a new layer of cement to the face of the pool. It is done in cases where the outer layer is aged or in need of replacement and it dramatically changes the color of the pool. Our company completely resurfaces your pool with only the highest quality cement, namely Diamond-Brite, and it comes in any color that you would like.</p>
                      </v-card>
                    </v-card>
                  </v-layout>

                  <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/gallery/9.png"></v-img>
                    </v-card>
                  </v-layout>

                  <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="90%">
                      <v-card height="97%" class="pa-3 elevation-0">
                        <p>The resurfacing process is similar for both plaster and fiberglass pools, starting with draining the pool using a sump pump. Resurfacing typically involves chipping out the damaged material, applying a new finish and acid washing the pool’s surface.</p>
                      </v-card>
                    </v-card>
                  </v-layout>

                  <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/gallery/10.png"></v-img>
                    </v-card>
                  </v-layout>

                  <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="90%">
                      <v-card height="97%" class="pa-3 elevation-0">
                        <p>Our innovative process creates a strong, smooth, beautiful surface that comes with an incredible 25-year limited warranty. Compare that to other resurfacing processes such as marcite, vinyl and painting that offer little or no warranty and the difference is obvious.</p>
                      </v-card>
                    </v-card>
                  </v-layout>
                  <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/1.jpg"></v-img>
                    </v-card>
                  </v-layout>
                    <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/2.jpg"></v-img>
                    </v-card>
                  </v-layout>
                    <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/3.jpg"></v-img>
                    </v-card>
                  </v-layout>
                    <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/4.jpg"></v-img>
                    </v-card>
                  </v-layout>
                    <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/5.jpg"></v-img>
                    </v-card>
                  </v-layout>
                    <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/6.jpg"></v-img>
                    </v-card>
                  </v-layout>
                    <v-layout justify-center class="ma-4 mb-4">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="97%" src="../assets/images/7.jpg"></v-img>
                    </v-card>
                  </v-layout>
                </v-card>
              </v-flex>
              <!------------------------------- Begin Our Products section ------------------------------------------->

              <v-flex xs12 sm12 md12 lg12 xl2>
                <v-divider class="mt-4"></v-divider>
                <v-layout justify-center align-center>
                  <v-card flat class="mt-1">
                    <v-card-text
                      class="display-1 font-weight-black blue--text text-decoration-underline"
                    >Our Services</v-card-text>
                  </v-card>
                </v-layout>

                <v-layout justify-center align-center row class="ma-4 mb-4">
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250px">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/resurfacing.jpg"
                          >
                            <v-card-title>Resurfacing</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Using only Diamond-Brite material</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/tiling.jpg"
                          >
                            <v-card-title>Tiling</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Complete tiling or tiling repair</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/coping.png"
                          >
                            <v-card-title>Coping</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Protective cap or lip at top of pool wall</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/pavers.jpg"
                          >
                            <v-card-title>Paver Sealing</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Replace and renew pavers</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/enclosure.jpg"
                          >
                            <v-card-title>Pool Enclosures</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Complete enclosure repair, rebuild and rescreen</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/gutters.jpg"
                          >
                            <v-card-title>Gutters</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Complete gutter service, installation and maintenance</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/perogola.jpg"
                          >
                            <v-card-title>Perogalas</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Add this to provide shade and privacy</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/kitchen.png"
                          >
                            <v-card-title>Outdoor Kitchens</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Design the pool of your dreams</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <!-- ========================================================================== -->
                  <v-layout column justify-center align-center>
                    <v-card class="elevation-0" width="250">
                      <v-layout justify-center class="ma-4 mb-1">
                        <v-card color="blue" width="90%">
                          <v-img
                            class="white--text align-end"
                            height="90%"
                            src="../assets/images/driveways.jpg"
                          >
                            <v-card-title>Driveways</v-card-title>
                          </v-img>
                        </v-card>
                      </v-layout>

                      <v-layout justify-center>
                        <v-card color="blue" class="elevation-1 pa-0" width="100%">
                          <v-card
                            height="95%"
                            class="text-wrap blue--text pa-2"
                            style=" font-size: 20px; font-weight: bold;"
                          >Concrete, pavers and textures</v-card>
                        </v-card>
                      </v-layout>
                    </v-card>
                  </v-layout>
                </v-layout>
                <v-divider></v-divider>

                <!-- ===========================begin another section -------------------------------- -->
                <v-layout justify-center align-center>
                  <v-card flat class="mt-1">
                    <v-card-text
                      class="display-1 font-weight-black blue--text text-decoration-underline"
                    >Colors</v-card-text>
                  </v-card>
                </v-layout>

                <v-layout justify-center align-center row class="ma-4 mb-4">
                  <!-- ========================================================================== -->

                  <v-layout justify-center class="ma-4 mb-1">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="96%" src="../assets/images/colors.jpg"></v-img>
                    </v-card>
                  </v-layout>
                  <v-layout justify-center class="ma-4 mb-1">
                    <v-card color="blue" width="80%">
                      <v-img
                        class="white--text"
                        height="96%"
                        src="../assets/images/diamond-brite.png"
                      ></v-img>
                    </v-card>
                  </v-layout>
                  <v-layout justify-center class="ma-4 mb-1">
                    <v-card color="blue" width="80%">
                      <v-img class="white--text" height="96%" src="../assets/images/colors2.png"></v-img>
                    </v-card>
                  </v-layout>
                  <v-layout justify-center class="ma-4 mb-1">
                    <v-card color="blue" width="90%">
                      <v-card height="97%" class="pa-3 elevation-0">
                        <p>
                          We use only Diamond-Brite Aggregate Pool finishes due to their superior quality and durability.
                          The colors that are in this picture are the most common finishes that we apply and we have the
                          ability to order any type of Diamond-Brite finish on demand.
                        </p>
                      </v-card>
                    </v-card>
                  </v-layout>
                </v-layout>

                <v-layout justify-center align-center>
                  <QAform></QAform>
                </v-layout>
                <!--------------------- Begin Guarantee Section --------------------------------->
                <v-card class="my-4">
                  <v-card-title class="blue">
                    <v-icon large class="ma-0 white--text">mdi-check-decagram</v-icon>
                    <span class="title white--text font-weight-light ml-2">Price Match Guarantee</span>
                  </v-card-title>

                  <v-card-text class="pa-2">
                    We check competitor prices every day to make sure
                    that you pay less for your services. You won't find a service
                    cheaper. But if you do, we'll refund you the difference. Thats
                    our Price Match Guarantee and it's just one of the ways we help
                    deliver the best products!
                  </v-card-text>

                  <v-card-text
                    class="pa-2 display-1 blue--text font-weight-black"
                  >With our Price Match Guarantee, you can:</v-card-text>
                  <v-card-text>
                    <ul>
                      <li>Be confident you always have the lowest price</li>
                      <li>Save time searching for cheaper services</li>
                      <li>Get the lowest prices on different types and colors</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
const slide1 = require("../assets/images/gallery/resurface2.jpg");

const slide8 = require("../assets/images/gallery/resurface5.jpg");
const slide9 = require("../assets/images/gallery/resurface6.jpg");
const slide10 = require("../assets/images/gallery/resurface7.jpg");
const slide11 = require("../assets/images/gallery/resurface1.jpg");

import QAform from "./QAform";

export default {
  name: "Home",
  components: {
    QAform,
  },
  methods: {},
  computed: {},
  data() {
    return {
      items: [
        {
          src: slide1,
        },

     

        {
          src: slide8,
        },
        {
          src: slide9,
        },

        {
          src: slide10,
        },
        {
          src: slide11,
        },
      ],
    };
  },
};
</script>

<style scoped>
.fix {
  word-break: normal;
}
</style>